<template>
  <head>
  <title>Constantin Lungu | Analytics Consultant & Data Engineer</title>
  <meta name="description" content="Constantin Lungu - Independent Analytics consultant specializing in Data Engineering, Business Intelligence, and Data Analysis. Connect on LinkedIn or read insights on data at Datawise.dev.">
  <meta name="keywords" content="Constantin Lungu, BigQuery, Analytics Consultant, Data Engineering, Business Intelligence, Data Analysis, Tech Blog, LinkedIn, Datawise.dev">
  <meta name="author" content="Constantin Lungu">
  <meta property="og:title" content="Constantin Lungu | Analytics Consultant & Data Engineer">
  <meta property="og:description" content="Helping businesses solve problems with data. Visit Constantin's tech blog or connect on LinkedIn.">
  <meta property="og:image" content="/assets/constantin.png">
  <meta property="og:url" content="https://cnstlungu.com"> <!-- Update with your URL -->
  <meta property="og:type" content="website">
</head>
  <div id="app">
    <img class="costin" alt="Constantin Lungu" src="./assets/constantin.png">
    <br>
    <div class="achievements-container">
      <img class="achievements" alt="AWS Certified Data Analytics - Specialty" src="./assets/aws-dataanalytics-certification.png">
      <img class="achievements" alt="Google Certified Professional Data Engineer" src="./assets/google-certified-data-engineer.png">
    </div>
    <Homepage msg="Constantin Lungu"/>
  </div>
</template>

<script>
import Homepage from './components/Homepage.vue'

export default {
  name: 'App',
  components: {
    Homepage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}

.costin {
  margin-bottom: 20px;
  border-radius: 50%;
  max-width: 300px;
}

.achievements-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.achievements {
  max-width: 100px;
  border-radius: 8px;
}
</style>