<template>
  <section class="hello">
    <header>
      <h1>{{ msg }}</h1>
    </header>
    <main>
      <p class="description">
        Hi there! I'm Constantin, an independent Analytics consultant, focused on Data Engineering, Business Intelligence, and Data Analysis.
      </p>
      <p>My daily work revolves around solving business problems with data and empowering others to do so.</p>
      <p>
        I also write occasionally on
        <a href="https://datawise.dev" target="_blank" rel="noopener">my tech blog</a>.
      </p>
    </main>
    <footer>
      <h3>Reach out to me at:</h3>
      <ul>
        <li><a href="https://www.linkedin.com/in/constantin-lungu-668b8756/" target="_blank" rel="noopener">LinkedIn</a></li>
        <li><a href="https://www.notjustsql.com" target="_blank" rel="noopener">Newsletter</a></li>
        <li><a href="https://medium.com/@cnstlungu" target="_blank" rel="noopener">Medium</a></li>
        <li><a href="https://github.com/cnstlungu" target="_blank" rel="noopener">Github</a></li>
      </ul>
    </footer>
  </section>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h1 {
  color: #3581f7; /* Use the primary blue color */
}

.description {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 20px;
  line-height: 1.6;
}

h3 {
  margin: 40px 0 10px;
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #2d6ef7;
  text-decoration: none;
}

a:hover {
  color: #3581f7; /* Brighter blue for hover */
  text-decoration: underline;
}
</style>
